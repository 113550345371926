import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconBox } from '~/common/components';
import { Any, cx } from '~/common/utils';
import UploadFileBox from '~/components/ui/upload-file-box';
import {
  orderFileUploadRemove,
  resetOrderFiles,
  useUploadOrderFile,
} from '~/redux/modules/file-upload/file-upload.actions';

type Props = {
  comment: string;
  isPosting: boolean;
  onChange: (arg0: React.SyntheticEvent<EventTarget>) => void;
  onSend: (arg0: Array<Any>) => Promise<Any>;
  onClose: () => void;
};

const filesSelector = (state) => state.fileUpload.orderFiles;

type FilePayloadType = {
  uuid: string;
  isUploading: boolean;
  name: string;
  hasError: boolean;
  errorMessage: string;
  savedName: string;
};
type FileUploadType = Record<string, FilePayloadType>;

/** todo: connect the component to files store */
export const CommentFilesPopup = ({ comment, isPosting, onChange, onSend, onClose }: Props) => {
  const dispatch = useDispatch();
  const files: FileUploadType = useSelector(filesSelector);
  const fileValues: Array<FilePayloadType> = Object.values(files) as Array<Any>;
  const [isCommentError, setCommentError] = useState<boolean>(false);
  const areFilesUploaded = fileValues.some(({ isUploading }) => isUploading);
  const orderFileUploader = useUploadOrderFile('order_comment');

  const handleFileUpload = useCallback(
    (file: File) => {
      orderFileUploader.upload(file).catch(console.error);
    },
    [orderFileUploader],
  );
  const handleFileRemove = useCallback(
    (name) => {
      dispatch(orderFileUploadRemove(name));
    },
    [dispatch],
  );

  const handleChange = (event) => {
    if (isCommentError) {
      // Prevent rerender if there is no error
      setCommentError(false);
    }

    onChange(event);
  };

  const handleSend = () => {
    if (!comment) {
      setCommentError(true);
      return;
    }

    const normalizedFiles = fileValues.map(({ name, uuid }) => ({
      name,
      uuid,
    }));
    onSend(normalizedFiles)
      .then(() => {
        dispatch(resetOrderFiles());
        onClose();
      })
      .catch(() => {});
  };

  return (
    <div className="iopm__container comment-files-popup">
      <h3 className="iop__title">Upload file</h3>
      <TextField
        fullWidth
        name="send"
        placeholder="Leave a comment"
        value={comment}
        onChange={handleChange}
        multiline
        maxRows={10}
        error={isCommentError}
      />
      <div className="cfp__upload-container">
        <UploadFileBox files={files} uploadFile={handleFileUpload} removeFile={handleFileRemove} />
      </div>
      <div className="flex-justify-end full-width">
        <Button
          className="send-files flex-center"
          size="s"
          color="secondary"
          onClick={handleSend}
          loading={isPosting}
          disabled={areFilesUploaded}
        >
          <span className={cx(isPosting && 'opacity-0')}>Send</span>{' '}
          <IconBox
            icon={faPaperPlaneTop}
            className={cx('text-6', isPosting && 'opacity-0')}
            size="s"
          />
        </Button>
      </div>
    </div>
  );
};
