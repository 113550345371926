/*
  We get validation rules from `component` `fields` from backend response  on `/order/create`
  The names of the validation rules are the same with functions below

  Unused `rule` flag is as a plug for dynamic validation rules adding

  Here is data, that every function can recieve as parameter:

  `name` | `param``
  required: boolean
  requiredWith: string
  min: number
  max: number
  minLength: number
  maxLength: number
  regex: string
  email: boolean
  integer: boolean (integer only)
  numeric: boolean (integer/float)
  string: boolean
  same: string
  requiredUnless: string
*/

export const regExpParser = (str) => {
  // Original RegExp string is "/exp/i";
  const lastIndex = str.lastIndexOf('/');
  const regexp = str.slice(1, lastIndex);
  const flags = str.slice(lastIndex + 1);

  // [regexp: "exp", flags: "i"];
  return [regexp, flags];
};

const required =
  (rule, message = 'Field is required') =>
  (value) =>
    value !== undefined ? undefined : message;

// https://gist.github.com/badsyntax/719800
const email =
  (rule, message = 'Email is invalid') =>
  (value) => {
    const RegEx = new RegExp(
      // eslint-disable-next-line max-len, no-control-regex
      /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/,
    );

    if (!value || RegEx.test(value)) {
      return undefined;
    }

    return message;
  };

const numeric =
  (rule, message = 'The value is not number') =>
  (value) =>
    typeof value === 'number' && !Number.isNaN(value) ? undefined : message;

const integer =
  (rule, message = 'The value is not integer') =>
  (value) =>
    value === null || value === undefined || Number.isInteger(value) ? undefined : message;

const min =
  (minValue, message = `Value cannot be lower than ${minValue}`) =>
  (value) =>
    typeof value === 'number' && parseFloat(value) < minValue ? message : undefined;

const max =
  (maxValue, message = `Value cannot be greater than ${maxValue}`) =>
  (value) =>
    typeof value === 'number' && parseFloat(value) > maxValue ? message : undefined;

const maxLength =
  (maxValue, message = `Must have less than ${maxValue} characters`) =>
  (value) => {
    if (!value) return;

    if (Array.isArray(value)) {
      return value.length > maxValue ? message : undefined;
    }

    return value.toString().length > maxValue ? message : undefined;
  };

const minLength =
  (minValue, message = `Must have at least ${minValue} characters`) =>
  (value) => {
    if (!value) return;

    if (Array.isArray(value)) {
      value.length < minValue ? message : undefined;
    }

    return value.toString().length < minValue ? message : undefined;
  };

const regex =
  (regexp, message = 'Invalid format') =>
  (value) => {
    if (!value) {
      return undefined;
    }

    const [pattern, flags] = regExpParser(regexp);
    const regExp = new RegExp(pattern, flags);
    return regExp.test(value) ? undefined : message;
  };

const string =
  (rule, message = 'Not a string type') =>
  (value) =>
    typeof value === 'string' ? undefined : message;

// `same` function returns an error if current values doesn't equal to the value of fieldName
const same =
  (fieldName, message = `Should match with ${fieldName}`) =>
  (value, values) =>
    value === values[fieldName] ? undefined : message;

// `requiredWith` function returns an error if current values doesn't, and the value of fieldName does
const requiredWith =
  (fieldName, message = `Field is required with ${fieldName}`) =>
  (value, values) =>
    !value && values[fieldName] ? message : undefined;

// `requiredUnless` function returns an error if value exist when fieldName equals fieldName
const requiredUnless =
  (details, message = 'Required unless validation failed') =>
  (value, values) => {
    const [fieldName, ...fieldValues] = details.split(',');

    let normalizedValue = fieldValues.length > 1 ? fieldValues : !!fieldValues[0];

    if (Array.isArray(normalizedValue)) {
      if (!values[fieldName] || normalizedValue.includes(values[fieldName])) {
        return undefined;
      }

      return value ? undefined : message;
    }

    // Normalize Boolean: "true" > true
    if (/(true|false)/.test(fieldValues)) {
      normalizedValue = JSON.parse(fieldValues);
    }

    // Normalize Number: "4" > 4
    const number = parseFloat(fieldValues);
    if (!Number.isNaN(number)) {
      normalizedValue = number;
    }

    return values[fieldName] !== normalizedValue && !value ? message : undefined;
  };

const parseStringFieldValue = (value) => {
  if (value === 'null') return null;
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (value === 'undefined') return undefined;
  return value;
};

const requiredIf =
  (rule, message = 'Field is required') =>
  (value, values) => {
    if (value) return;

    const [fieldName, fieldValue] = rule.split(',');
    return values[fieldName] === parseStringFieldValue(fieldValue) ? message : undefined;
  };

export default {
  email,
  min,
  max,
  maxLength,
  minLength,
  numeric,
  required,
  regex,
  string,
  integer,
  same,
  requiredWith,
  requiredUnless,
  requiredIf,
};
