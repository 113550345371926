export const qk = {
  sessions: ['sessions'],
  styleTemplates: ['styleTemplates'],
  init: ['init'],
  login: ['login'],
  me: ['me'],
  oauthLogin: ['oauth'],
  signup: ['signup'],
  briefQuestions: ['orders', 'brief-questions'],
  resetPasswordValidate: ['reset-password', 'validate'],
} as const;
