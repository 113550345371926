export const ORDER_FILE_UPLOAD_REQUEST = 'ORDER_FILE_UPLOAD_REQUEST';
export const ORDER_FILE_UPLOAD_SUCCESS = 'ORDER_FILE_UPLOAD_SUCCESS';
export const ORDER_FILE_UPLOAD_FAILURE = 'ORDER_FILE_UPLOAD_FAILURE';
export const ORDER_FILE_UPLOAD_REMOVE = 'ORDER_FILE_UPLOAD_REMOVE';
export const RESTORE_ORDER_FILES = 'RESTORE_ORDER_FILES';
export const ORDER_FILE_PROGRESS = 'ORDER_FILE_PROGRESS';

export const RESET_ORDER_FILES = 'RESET_ORDER_FILES';
export const RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE';

export const STYLE_FILE_UPLOAD_REQUEST = 'STYLE_FILE_UPLOAD_REQUEST';
export const STYLE_FILE_UPLOAD_SUCCESS = 'STYLE_FILE_UPLOAD_SUCCESS';
export const STYLE_FILE_UPLOAD_FAILURE = 'STYLE_FILE_UPLOAD_FAILURE';
export const STYLE_FILE_UPLOAD_REMOVE = 'STYLE_FILE_UPLOAD_REMOVE';
export const RESTORE_STYLE_FILE = 'RESTORE_STYLE_FILE';
export const STYLE_FILE_PROGRESS = 'STYLE_FILE_PROGRESS';

export const STYLE_PREFERENCES_FILE_UPLOAD_REQUEST = 'STYLE_PREFERENCES_FILE_UPLOAD_REQUEST';
export const STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS = 'STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS';
export const STYLE_PREFERENCES_FILE_UPLOAD_FAILURE = 'STYLE_PREFERENCES_FILE_UPLOAD_FAILURE';
export const STYLE_PREFERENCES_FILE_UPLOAD_REMOVE = 'STYLE_PREFERENCES_FILE_UPLOAD_REMOVE';
export const STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED =
  'STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED';
export const STYLE_PREFERENCES_FILE_UPLOAD_DROP = 'STYLE_PREFERENCES_FILE_UPLOAD_DROP';

export const TOGGLE_OWN_FILES_CHECKBOX = 'TOGGLE_OWN_FILES_CHECKBOX';
export const STORE_ABORT_CONTROLLER = 'STORE_ABORT_CONTROLLER';
export const REMOVE_ABORT_CONTROLLER = 'REMOVE_ABORT_CONTROLLER';
export const STOP_FILE_UPLOAD = 'STOP_FILE_UPLOAD';

export const ORDER_REFERENCE_FILES = {
  REQUEST: 'ORDER_REFERENCE_FILES_REQUEST',
  SUCCESS: 'ORDER_REFERENCE_FILES_SUCCESS',
  FAILURE: 'ORDER_REFERENCE_FILES_FAILURE',
  PROGRESS: 'ORDER_REFERENCE_FILES_PROGRESS',
  REMOVE: 'ORDER_REFERENCE_FILES_REMOVE',
  RESTORE: 'ORDER_REFERENCE_FILES_RESTORE',
};
