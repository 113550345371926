import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { axios } from '~/root';
import { qk } from '~/root/query-keys';

export const useValidateToken = (token: string) => {
  const history = useHistory();

  return useQuery({
    queryKey: qk.resetPasswordValidate,
    queryFn: () => {
      return axios.get(`/v1/users/verify/reset-password/${token}`).catch((error) => {
        if (error?.response?.data?.status === 'error') {
          history.replace('/');
        }
        throw error;
      });
    },
  });
};
