import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { addDays, isSameDay, max, min } from 'date-fns';
import Dayzed, { DateObj, GetDatePropsOptions, RenderProps } from 'dayzed';
import {
  ComponentProps,
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useState,
} from 'react';
import { cx, getFullMonthName, Overwrite } from '~/common/utils';
import { IconButton } from '../IconContainers';
import { Button } from '../Interactives';
import { Tooltip } from '../Tooltip';
import css from './Datepicker.module.scss';

const weekdayNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const UnselectableTooltip = ({
  selectable,
  children,
  reason = 'No data for this date',
}: {
  selectable: boolean;
  children: JSX.Element;
  reason?: string;
}) => {
  if (selectable) {
    return children;
  }
  return (
    <Tooltip compensateOffset={5} content={reason}>
      {children}
    </Tooltip>
  );
};

type DayProps = ComponentProps<typeof Button.Base> & DateObj & { reason?: string };

const Day = ({
  date,
  selected,
  selectable,
  prevMonth,
  nextMonth,
  today,
  reason,
  ...props
}: DayProps) => {
  return (
    <UnselectableTooltip selectable={selectable} reason={reason}>
      <Button.Base
        {...props}
        className={cx(css.item, 'rounded-full', {
          'text-text-200 cursor-not-allowed': !selectable && !reason,
          'text-text-300 cursor-not-allowed': !selectable && reason,
          'text-other-500': selectable && (prevMonth || nextMonth),
          'bg-other-100': selected,
          [css.hoverable]: selectable,
        })}
      >
        {date.getDate()}
      </Button.Base>
    </UnselectableTooltip>
  );
};

type DayMapper = (
  key: string,
  dateObj: DateObj,
  getDateProps: RenderProps['getDateProps'],
) => ReactNode;

const defaultDayMapper: DayMapper = (key, dateObj, getDateProps) => {
  return <Day key={key} {...getDateProps({ dateObj })} {...dateObj} />;
};

type CalendarProps = RenderProps & {
  dayMapper?: DayMapper;
};

const Calendar = ({
  calendars,
  getBackProps,
  getForwardProps,
  getDateProps,
  dayMapper = defaultDayMapper,
  ...props
}: Overwrite<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, CalendarProps>) => {
  if (!calendars.length) {
    return null;
  }

  return (
    <div {...props} className="font-brand-b1 text-text-500 max-w-[280px] mx-auto shrink-0">
      {calendars.map((calendar) => (
        <div key={`${calendar.month}${calendar.year}`}>
          <div className="flex items-center justify-between gap-2">
            <IconButton
              className={cx(css.prevNext, css.hoverable)}
              icon={faChevronLeft}
              {...getBackProps({ calendars })}
            />
            <div className="uppercase font-brand-c3">
              {getFullMonthName(calendar.month)} {calendar.year}
            </div>
            <IconButton
              className={cx(css.prevNext, css.hoverable)}
              icon={faChevronRight}
              {...getForwardProps({ calendars })}
            />
          </div>
          <div className="grid grid-cols-7">
            {weekdayNames.map((weekday) => (
              <div
                key={`${calendar.month}${calendar.year}${weekday}`}
                className={cx(css.item, 'cursor-default')}
              >
                {weekday}
              </div>
            ))}
            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, index) => {
                const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                return dateObj ? (
                  dayMapper(key, dateObj, getDateProps)
                ) : (
                  <div key={key} className={css.item} />
                );
              }),
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

type SharedProps = Pick<ComponentProps<typeof Dayzed>, 'minDate' | 'maxDate'>;

type DatepickerProps = SharedProps & {
  value: Date | null;
  onChange: (value: Date) => void;
  blockedDays?: { date: Date; reason: string }[];
  maxDateBlockReason?: string;
};

export const Datepicker = ({
  minDate,
  maxDate,
  value,
  onChange,
  blockedDays,
  maxDateBlockReason,
}: DatepickerProps) => {
  const handleDateSelection = ({ date }: DateObj) => {
    onChange(date);
  };

  return (
    <Dayzed
      minDate={minDate ? addDays(minDate, -1) : undefined}
      maxDate={maxDate}
      date={value || undefined}
      selected={value || undefined}
      onDateSelected={handleDateSelection}
      showOutsideDays
      firstDayOfWeek={1}
      render={(dayzedData) => {
        const getDateProps = (data: GetDatePropsOptions & { reason?: string }) => {
          const blockedDay = blockedDays?.find(({ date }) =>
            isSameDay(new Date(date), data.dateObj.date),
          );

          if (blockedDay) {
            data.dateObj.selectable = false;
            data.reason = blockedDay.reason;
          }
          if (maxDateBlockReason && maxDate && maxDate.getTime() < data.dateObj.date.getTime()) {
            data.reason = maxDateBlockReason;
          }

          return dayzedData.getDateProps(data);
        };

        return <Calendar {...dayzedData} getDateProps={getDateProps} />;
      }}
    />
  );
};

type RangeDayProps = DayProps & {
  isInRange: boolean;
  isHovered: boolean;
  roundSelected: boolean;
  forceRoundSelected: boolean;
  roundedSide: 'left' | 'right' | null;
};

const RangeDay = ({
  date,
  selected,
  selectable,
  prevMonth,
  nextMonth,
  isInRange,
  isHovered,
  roundSelected,
  forceRoundSelected,
  roundedSide,
  ...props
}: RangeDayProps) => (
  <UnselectableTooltip selectable={selectable}>
    <button
      {...props}
      style={{ '--content': date.getDate() } as CSSProperties}
      className={cx(css.item, css.rangeDay, {
        'text-other-200': !selectable,
        'text-other-500': selectable && (prevMonth || nextMonth),
        'rounded-l-full': roundedSide === 'left',
        'rounded-r-full': roundedSide === 'right',
        'rounded-full':
          (isHovered && !roundedSide && !isInRange) ||
          (isHovered && selected && roundSelected) ||
          forceRoundSelected,
        [css.fill]: selected || isInRange || isHovered,
        [css.rangeDaySelected]: selected,
      })}
    />
  </UnselectableTooltip>
);

export type DateRange = {
  start: Date;
  end: Date;
};

type PartialDateRange = {
  start: Date;
  end: null;
};

type DateRangePickerProps = SharedProps & {
  value: DateRange;
  onChange: (value: DateRange) => void;
};

export const DateRangePicker = ({ minDate, maxDate, value, onChange }: DateRangePickerProps) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  const [dates, setDates] = useState<DateRange | PartialDateRange>(value);

  const [offset, setOffset] = useState(0);

  const getRangeDayProps = (date: Date) => {
    const time = date.getTime();
    const startTime = dates.start.getTime();
    const result: { isInRange: boolean; roundedSide: 'left' | 'right' | null } = {
      isInRange: false,
      roundedSide: null,
    };

    if (hoveredDate) {
      const hoveredTime = hoveredDate.getTime();
      if (isSameDay(hoveredDate, date) && !dates.end) {
        result.roundedSide = hoveredTime > startTime ? 'right' : 'left';
      }
      if (isSameDay(dates.start, date) && !dates.end) {
        result.roundedSide = startTime > hoveredTime ? 'right' : 'left';
      }
      result.isInRange =
        (hoveredTime < time && time < startTime) || (startTime < time && time < hoveredTime);
    }

    if (dates.end) {
      const endTime = dates.end.getTime();
      if (isSameDay(dates.start, date)) {
        result.roundedSide = 'left';
      }
      if (isSameDay(dates.end, date)) {
        result.roundedSide = 'right';
      }
      result.isInRange = startTime < time && time < endTime;
    }

    return result;
  };

  const handleDateSelection = ({ date }: DateObj) => {
    if (!dates.end) {
      const newRange = { start: min([date, dates.start]), end: max([date, dates.start]) };
      setDates(newRange);
      onChange(newRange);
      if (date.getTime() < dates.start.getTime()) {
        setOffset(0);
      }
    } else {
      setDates({ start: date, end: null });
      setOffset(0);
    }
  };

  const handleMouseLeave = () => setHoveredDate(null);

  const dayMapper: DayMapper = (key, dateObj, getDateProps) => (
    <RangeDay
      key={key}
      {...dateObj}
      {...getDateProps({ dateObj })}
      {...getRangeDayProps(dateObj.date)}
      isHovered={hoveredDate ? isSameDay(hoveredDate, dateObj.date) : false}
      roundSelected={dates.end === null}
      forceRoundSelected={dates.end === null && !hoveredDate}
      onMouseEnter={() => setHoveredDate(dateObj.date)}
    />
  );

  return (
    <Dayzed
      minDate={minDate ? addDays(minDate, -1) : undefined}
      maxDate={maxDate}
      date={dates.start}
      selected={dates.end ? [dates.start, dates.end] : [dates.start]}
      onDateSelected={handleDateSelection}
      showOutsideDays
      firstDayOfWeek={1}
      offset={offset}
      onOffsetChanged={setOffset}
      render={(dayzedData) => (
        <Calendar {...dayzedData} dayMapper={dayMapper} onMouseLeave={handleMouseLeave} />
      )}
    />
  );
};
