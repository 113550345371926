import { faFile, faLoader, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { HTMLAttributes } from 'react';
import { uploadFileIcon } from '~/assets/images/icons';
import { Button, IconBox } from '~/common/components';
import { DropzoneProps } from '~/common/hooks';
import { cx, tw } from '~/common/utils';

interface FileUploadPlaceholderProps extends Partial<DropzoneProps<HTMLDivElement>> {
  icon?: string;
  text?: string;
  description?: string;
  className?: string;
  containerClassName?: string;
}

export const FileUploadPlaceholder = ({
  icon = uploadFileIcon,
  text = 'Choose a file or drag it here',
  description,
  className,
  containerClassName,
  ...props
}: FileUploadPlaceholderProps) => {
  return (
    <div
      className={cx('flex relative w-full def:h-7 cursor-pointer text-center', containerClassName)}
    >
      <div
        className={cx(
          tw`
            absolute l-0 t-0 w-full h-full
            def:flex def:gap-1 def:justify-center def:items-center
            def:px-3 def:py-1 def:font-brand-b3 def:bg-other-50
            def:rounded-md def:border def:border-dashed def:border-other-400
          `,
          className,
        )}
        {...props}
      >
        <img src={icon} />
        <span className="text-text-300">{text}</span>
        {description && <span className="font-brand-sm text-text-300">{description}</span>}
      </div>
    </div>
  );
};

interface UploadFileProps
  extends Partial<DropzoneProps<HTMLDivElement>>,
    HTMLAttributes<HTMLDivElement> {
  name: string;
  progress?: number;
  disabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  clear?: () => void;
}

export const UploadFile = ({
  name,
  isError = false,
  isLoading,
  progress = 0,
  disabled = false,
  className,
  clear,
  ...props
}: UploadFileProps) => (
  <div
    className={cx(
      'flex items-center w-full gap-1 def:p-1 rounded border border-solid def:border-other-400',
      className,
      {
        'border-error-300 bg-error-100': isError,
      },
    )}
    {...props}
  >
    <div className="w-3 h-3 flex items-center justify-center">
      <IconBox
        size="m"
        icon={isLoading ? faLoader : faFile}
        className={cx('def:text-other-400', {
          'animate-spin': isLoading,
          'text-error-200': isError,
        })}
      />
    </div>
    <p
      className={cx('def:text-text-500 font-brand-b3 flex-grow truncate text-left', {
        'text-text-400': isError,
      })}
    >
      {name}
    </p>
    {isLoading && <span className="ml-2 text-text-400 whitespace-nowrap">{progress}%</span>}
    {!disabled && clear && (
      <Button.Icon
        icon={isLoading ? faClose : faTrashCan}
        size="s"
        className={cx('def:text-other-500', { 'text-error-300': isError })}
        onClick={clear}
        data-stop-propagation
      />
    )}
  </div>
);
