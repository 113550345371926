import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isToday, isSameDay } from 'date-fns';
import { FieldProps } from 'formoid';
import { NonEmptyArray } from 'formoid/lib/Array';
import { ComponentProps, useState } from 'react';
import { cx, formatDateShortDots, Overwrite } from '~/common/utils';
import { IconBox } from '../IconContainers';
import { Errors, FormElementLabel, Input } from '../Input';
import { Popover } from '../Popover';
import { Select } from '../Select';
import { Datepicker } from './Datepicker';

type DatepickerFieldProps = Overwrite<
  ComponentProps<typeof Input> & ComponentProps<typeof Datepicker>,
  Overwrite<
    FieldProps<Date | null>,
    {
      touched?: boolean;
      disabled?: boolean;
      errors?: NonEmptyArray<string> | null;
      onBlur?: () => void;
    }
  > & {
    value: Date | null;
    nullable?: boolean;
    wrapperClassName?: string;
    maxDateBlockReason?: string;
  }
>;

const hours = Array.from({ length: 24 }, (_, i) => {
  const hour = i % 12 === 0 ? 12 : i % 12;
  const period = i < 12 ? 'AM' : 'PM';

  return {
    name: `${hour.toString().padStart(2, '0')}:00 ${period}`,
    value: i,
  };
});

export const DateTimePickerField = ({
  errors,
  value,
  // so you can set it as undefined above atleast :shrug:
  minDate = new Date(),
  maxDate = new Date(),
  onBlur,
  onChange,
  nullable,
  wrapperClassName,
  blockedDays,
  maxDateBlockReason,
  title,
  hint,
  hintClickable,
  required,
  ...inputProps
}: DatepickerFieldProps) => {
  const [date, setDate] = useState<Date | null>(value || null);
  const [time, setTime] = useState<number | null>(value?.getHours() ?? null);
  const datepickerPopoverState = useState(false);
  const minTime = date && isToday(date) ? minDate.getHours() : -1;
  const maxTime = date && isSameDay(date, maxDate) ? maxDate.getHours() : Infinity;
  const filteredHourOptions = hours.filter(({ value }) => value >= minTime && value <= maxTime);

  const onDateChange = (date: Date) => {
    setDate(date);
    if (time !== null) {
      if (isToday(date)) {
        onTimeChange(Math.max(time, minDate.getHours()), date);
      } else if (isSameDay(date, maxDate)) {
        onTimeChange(Math.min(time, maxDate.getHours()), date);
      } else {
        onTimeChange(time, date);
      }
    }
  };

  const onTimeChange = (hours: number, date: Date) => {
    date.setHours(hours);
    date.setMinutes(0);
    date.setSeconds(0);
    setTime(hours);
    onChange(date);
  };

  const onClear: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setTime(null);
    setDate(null);
    onChange(null);
    onBlur?.();
  };

  return (
    <div>
      <FormElementLabel title={title} hint={hint} required={required} />
      <Popover
        className={'h-[340px]'}
        onClose={onBlur}
        compensateOffset={8}
        placement="bottom-start"
        matchTriggerWidth
        externalState={datepickerPopoverState}
        trigger={({ ref, ...props }) => (
          // This wrapper is needed for datepicker to occupy 2 fields widthwise
          <div ref={ref} className={cx(wrapperClassName, 'flex gap-2 flex-1')}>
            <Input
              className={cx('flex-1', inputProps.className)}
              {...props}
              {...inputProps}
              placeholder="Date"
              value={date ? formatDateShortDots(date) : ''}
              readOnly
              error={!!errors}
            >
              {nullable && date !== null ? (
                <IconBox className="cursor-pointer" size="s" icon={faTimes} onClick={onClear} />
              ) : (
                <IconBox size="s" className="text-other-600" icon={faCalendarAlt} />
              )}
            </Input>
            {/* This wrapper is needed to close datepicker, because Select does not provide an api to do so */}
            <div onClick={() => datepickerPopoverState[1](false)} className="flex-1">
              <Select
                options={filteredHourOptions}
                value={time}
                onChange={(hour: number) => onTimeChange(hour, date!)}
                placeholder="Time"
                disabled={!date}
                error={!!errors}
              />
            </div>
          </div>
        )}
        content={(props) => (
          <Datepicker
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            onChange={(date) => {
              onDateChange(date);
              props.onClose();
            }}
            blockedDays={blockedDays}
            maxDateBlockReason={maxDateBlockReason}
          />
        )}
      />
      {errors && <Errors errors={errors} />}
    </div>
  );
};
