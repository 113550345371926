import { FieldProps } from 'formoid';
import { ChangeEventHandler, ComponentProps, forwardRef } from 'react';
import { Any, Overwrite } from '~/common/utils';
import { Input } from './Input';

type DefaultProps = Omit<ComponentProps<typeof Input>, 'onChange' | 'type' | 'value'>;

type RefinedFieldProps<T> = Overwrite<FieldProps<T>, { value: T | null }>;

type TextFieldProps = Overwrite<
  RefinedFieldProps<string>,
  {
    onBlur?: () => void;
    disabled?: boolean;
    touched?: boolean;
  }
> & { type: 'text' | 'password' | 'email' };

type NumberFieldProps = RefinedFieldProps<number> & { type: 'number' };

type Props = DefaultProps & (TextFieldProps | NumberFieldProps);

export const InputField = forwardRef<HTMLDivElement, Props>(
  ({ errors, onChange, touched, type, value, ...props }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      switch (type) {
        case 'text':
        case 'password':
        case 'email':
          return onChange(event.currentTarget.value);
        case 'number': {
          const value = event.currentTarget.valueAsNumber;
          return onChange(Number.isNaN(value) ? (null as Any) : value);
        }
      }
    };

    return (
      <Input
        {...props}
        ref={ref}
        onChange={handleChange}
        value={value ?? ''}
        error={!!errors}
        errors={errors}
        type={type}
      />
    );
  },
);
