import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import { Link } from '~/common/components';
import { createQueryState } from '~/common/hooks';

const useUrlQueryState = createQueryState(
  z.object({
    url: z.string().url().nullable().catch(null),
  }),
);

export const Redirect = () => {
  const history = useHistory();
  const [{ url }] = useUrlQueryState();

  useEffect(() => {
    if (!url) {
      history.push('/');
    }
  }, [history, url]);

  if (!url) {
    return null;
  }

  return (
    <div className="grow flex flex-col items-center justify-center gap-1">
      <div className="font-brand-h4">You are leaving 24Slides</div>
      <div>If you trust this link, select it to continue</div>
      <Link to={url}>{url}</Link>
    </div>
  );
};
