import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEvent } from '~/common/hooks';
import { createContextPair } from '~/common/kits/context';
import { Any, Component, OnlyRequiredFields } from '~/common/utils';
import { ModalContentProps } from './Modal';
import { ModalCore } from './ModalCore';

type ModalProps<P = Any> = Omit<P, 'onClose'> & Partial<ModalContentProps>;

type ModalState<P = Any> = {
  component: (props: P) => JSX.Element | null;
  path: string;
  props?: ModalProps<P>;
  block?: () => void;
};

const useContextData = () => {
  const [state, setState] = useState<ModalState | null>(null);
  const { pathname } = useLocation();

  const open = (state: ModalState) => {
    setState(state);
  };

  const close = () => {
    setState(null);
  };

  const modalOpener =
    <P,>(
      // this ugly thing is needed to require second parameter when there's
      // required props in modal props
      ...args: ModalContentProps extends OnlyRequiredFields<P>
        ? [component: Component<P>, props?: ModalProps<P>]
        : [component: Component<P>, props: ModalProps<P>]
    ) =>
    () => {
      const [component, props] = args;
      const next = { component, props, path: window.location.pathname };
      if (state) {
        setState(null);
        // do not queue confirmation or other modal if we pressed back button
        // so much that we went to the other route
        if (state.path === window.location.pathname) {
          setTimeout(() => open(next), 150);
        }
      } else {
        open(next);
      }
    };

  const onClose = useEvent(() => {
    if (!state) {
      return;
    }

    if (state.block) {
      state.block();
    } else {
      setState(null);
      state?.props?.onClose?.();
    }
  });

  // close modals on route change
  useEffect(() => {
    onClose();
    //eslint-disable-next-line
  }, [pathname]);

  return {
    state,
    /** For private usage only */
    _setState: setState,
    onClose,
    close,
    open,
    modalOpener,
  };
};

export const useModalBlock = (callback?: () => void) => {
  const memoizedCallback = useEvent(callback ?? (() => {}));
  const { _setState } = useModalContext();
  const blocked = !!callback;

  useEffect(() => {
    _setState((prev) => prev && { ...prev, block: blocked ? memoizedCallback : undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocked]);
};

const ModalSlot = () => {
  const { state, onClose } = useModalContext();
  return (
    <ModalCore className="w-fit" opened={!!state} onClose={onClose}>
      {state && <state.component {...state.props} onClose={onClose} />}
    </ModalCore>
  );
};

const [useModalContext, withModalContext] = createContextPair(useContextData, <ModalSlot />);

/**
 * Wrap root route node, e.g. Customer.tsx with `withModalContext` hoc
 * and enjoy using modalOpener like this:
 * modalOpener(EditIndividualPricing, { data: something, onClose: something() })
 */
export { useModalContext, withModalContext };
