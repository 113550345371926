import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useCurrentUser } from '~/root/Auth';

// this is mainly used for guest order form submits, where new account is
// created on the last step and we need to login tab with the form from the
// secondary tab which gets opened from email
//
// could've used BroadcastChannel, but Safari man, it's always Safari
//
// polling part is in src/pages/Order-now/components/GuestSubmit/hooks.ts:33

export const initInvalidationShouldInvalidate = '_init-invalidation-should-invalidate';
export const initInvalidationRequestInvalidate = '_init-invalidation-request-invalidate';

export const useLoggedInTabInitInvalidation = () => {
  const user = useCurrentUser();
  const isLoggedIn = !!user;
  const client = useQueryClient();

  useEffect(() => {
    if (isLoggedIn && localStorage.getItem(initInvalidationRequestInvalidate)) {
      localStorage.setItem(initInvalidationShouldInvalidate, 'yes');
    }
  }, [client, isLoggedIn]);
};
