import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FieldProps } from 'formoid';
import { FocusEventHandler, ForwardedRef, ReactNode, forwardRef, useRef, useState } from 'react';
import { EMAIL_REG_EXP, LINK_REGEXP } from '~/common/utils';
import { Overwrite, cx, ignoreHandled, nonNullable } from '../utils';
import { IconBox } from './IconContainers';
import { Errors, FormElementLabel } from './Input';

export const extendedSplitter = ['Enter', ' ', ',', ';'];

export interface Chip<T extends string> {
  value: T;
  isValid: boolean;
}

type ChipsInputProps<T extends string> = Overwrite<
  React.HTMLAttributes<HTMLDivElement>,
  {
    title?: string;
    required?: boolean;
    hint?: ReactNode;
    hintClickable?: boolean;
    splitter?: string | string[];
    value: Chip<T>[];
    error?: boolean;
    onChange: (value: Chip<T>[]) => void;
    chipRenderer?: (props: ChipRendererProps<T>) => React.ReactNode;
    validateChip?: (chip: string) => chip is Chip<T>['value'];
    pasteProcessor?: (event: React.ClipboardEvent) => string[] | null;
    limit?: number;
    placeholder?: string;
    children?: ReactNode;
  }
>;

export const ChipsInput = forwardRef(
  <T extends string>(
    {
      title,
      hint,
      hintClickable,
      error = false,
      value: chips = [],
      className,
      required,
      splitter = 'Enter',
      validateChip,
      pasteProcessor = emailPasteProcessor,
      chipRenderer = defaultChipRenderer,
      onChange,
      onBlur,
      onClick,
      placeholder,
      children,
      limit,
      ...props
    }: ChipsInputProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [inputValue, setInputValue] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
      setInputValue(event.target.value);
    };

    const handleInputKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (event.key === 'Backspace' && !inputValue && chips.length) {
        event.preventDefault();
        const newChips = chips.slice();
        // we've checked that chips length is non-zero
        const chipToEdit = nonNullable(newChips.pop());
        onChange(newChips);
        setInputValue(chipToEdit.value);
        return;
      }

      if (
        typeof splitter === 'string'
          ? event.key === splitter
          : splitter.some((s) => event.key === s)
      ) {
        event.preventDefault();
        addChip();
      }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
      e.preventDefault();

      const nextStrings = pasteProcessor(e);
      if (!nextStrings) {
        return;
      }

      const nextChips = Array.from(
        new Set([...chips.map(({ value }) => value), ...nextStrings]),
        (value) => ({
          value: value as T,
          isValid: validateChip ? validateChip(value) : true,
        }),
      );

      onChange(nextChips);
    };

    const addChip = () => {
      const chipValue = inputValue.trim() as T;
      if (!chipValue || chips.find((chip) => chip.value === chipValue)) {
        return;
      }

      const isValid = validateChip ? validateChip(chipValue) : true;
      onChange([...chips, { value: chipValue, isValid }]);
      setInputValue('');
    };

    const removeChip = (chip: string) => {
      onChange(chips.filter((c) => c.value !== chip));
    };

    const handleContainerClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
      onClick && onClick(e);
      inputRef.current?.focus();
    };

    const handleBlur: FocusEventHandler<HTMLDivElement> = (e) => {
      onBlur?.(e);
      addChip();
    };

    const handleChipClick = (value: T) => {
      if (inputValue.replace(/\s/g, '') === '') {
        setInputValue(value);
        removeChip(value);
        inputRef.current?.focus();
      }
    };

    return (
      // preventDefault to not refocus the chips input when clicking controls in it
      <div ref={ref} className={cx('block', className)}>
        <FormElementLabel
          title={title}
          hint={hint}
          hintClickable={hintClickable}
          required={required}
        />
        <div
          className={cx(
            'min-h-[48px] p-1 pb-0 bg-other-50 border border-solid rounded resize-y overflow-y-scroll cursor-text focus-within:border-primary-200 focus-within:bg-primary-100 overscroll-contain',
            error ? 'border-error-200' : 'border-text-200',
          )}
        >
          <div
            {...props}
            className="grid grid-cols-[minmax(0,1fr),auto] gap-1"
            onMouseDown={(e) => {
              addChip();
              e.preventDefault();
            }}
            onClick={ignoreHandled(handleContainerClick)}
          >
            <div className="flex flex-wrap items-start content-start gap-1 pb-1">
              {chips.map(({ value, isValid }, index) => {
                const invalid = !isValid || index >= (limit ?? Infinity);
                return chipRenderer({
                  value,
                  isValid: !invalid,
                  remove: removeChip,
                  onClick: handleChipClick,
                });
              })}
              <input
                ref={inputRef}
                className="inline flex-grow-[2] bg-transparent h-4"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleInputKeyPress}
                onPaste={handlePaste}
                placeholder={!chips.length ? placeholder : undefined}
              />
            </div>
            {children}
          </div>
        </div>
        {limit && (
          <div
            className={cx(
              'text-right font-brand-b3',
              chips.length > limit ? 'text-error-300' : 'text-text-300',
            )}
          >
            {chips.length} / {limit}
          </div>
        )}
      </div>
    );
  },
) as <T extends string>(props: ChipsInputProps<T>) => JSX.Element;

export const emailPasteProcessor = (e: React.ClipboardEvent): string[] | null => {
  const paste = e.clipboardData.getData('text').toLowerCase();
  return paste.match(new RegExp(EMAIL_REG_EXP, 'g'));
};

export const stringPasteProcessor = (e: React.ClipboardEvent): string[] | null => {
  const paste = e.clipboardData.getData('text');
  return paste
    .replace(/[\r\n,;]/, ',')
    .split(',')
    .map((string) => string.trim());
};

export const linkPasteProcessor = (e: React.ClipboardEvent): string[] | null => {
  const paste = e.clipboardData.getData('text');
  return paste.match(new RegExp(LINK_REGEXP, 'g'));
};

export interface ChipRendererProps<T extends string> extends Chip<T> {
  remove: (value: T) => void;
  onClick?: (value: T) => void;
}

const defaultChipRenderer = <T extends string>({
  value,
  isValid,
  remove,
  onClick,
}: ChipRendererProps<T>) => (
  <div
    className={cx(
      'inline-flex items-center h-4 px-1 gap-1 border border-solid rounded-sm text-text-500 cursor-pointer truncate md:max-w-[calc(50%-4px)]',
      isValid ? 'border-other-400 bg-other-50' : 'border-error-300 bg-error-100',
    )}
    key={value}
    onClick={ignoreHandled(() => onClick?.(value))}
  >
    <span className="truncate" title={value}>
      {value}
    </span>
    <IconBox
      data-stop-propagation
      icon={faTimes}
      onClick={() => remove(value)}
      className={cx(isValid ? 'text-greyscale-400' : 'text-danger-400')}
    />
  </div>
);

type ChipsInputFieldProps<T extends string> = Overwrite<FieldProps<Chip<T>[]>, ChipsInputProps<T>>;

export const ChipsInputField = <T extends string>({
  className,
  errors,
  touched,
  ...props
}: ChipsInputFieldProps<T>) => {
  return (
    <div className={className}>
      <ChipsInput {...props} error={errors !== null} />
      {errors && <Errors errors={errors} />}
    </div>
  );
};
