import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
  useTransitionStatus,
} from '@floating-ui/react';
import { ReactNode, useRef } from 'react';
import { cx } from '~/common/utils';
import { FloatingDiv, withFloatingTree } from '../Floating';
import css from './ModalCore.module.scss';

const MAX_TAB_INDEX = 32767;

type Props = {
  className?: string;
  backdropClassName?: string;
  children: ReactNode;
  opened: boolean;
  onClose: () => void;
  onExiting?: () => void;
};

export const ModalCore = withFloatingTree(
  ({ className, backdropClassName, children, opened, onClose }: Props) => {
    const nodeId = useFloatingNodeId();

    const { refs, context } = useFloating({
      nodeId,
      open: opened,
      onOpenChange: (state) => {
        if (!state) {
          onClose?.();
        }
      },
    });
    const overlayRef = useRef(null);

    const { getFloatingProps } = useInteractions([
      useClick(context),
      useRole(context),
      useDismiss(context, {
        // TODO extend this once we know what other extensions our users want to use
        outsidePress: (event) => {
          const target = event.target as Element;
          return !target.shadowRoot && !target.closest('.hot-toast');
        },
      }),
    ]);

    const { isMounted, status } = useTransitionStatus(context);

    if (!isMounted) {
      return null;
    }

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <FloatingOverlay
            ref={overlayRef}
            lockScroll
            className={cx(backdropClassName, css.backdrop)}
            data-status={status}
          >
            <FloatingFocusManager context={context} initialFocus={MAX_TAB_INDEX}>
              <FloatingDiv
                {...getFloatingProps({
                  ref: refs.setFloating,
                  className: cx(className, css.modal),
                })}
                data-status={status}
                onTransitionEnd={() => status === 'close' && onClose?.()}
              >
                {children}
              </FloatingDiv>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      </FloatingNode>
    );
  },
);
