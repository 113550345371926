import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { assertQueryData } from '~/common/kits/query';
import { axios } from '~/root';
import { useSetUser } from '~/root/Auth';
import { qk } from '~/root/query-keys';
import {
  Billing,
  SetSubscriptionPlanPayload,
  subscriptionPlansSchema,
  RenewTopupPayload,
  TopupSubscriptionPayload,
  billingSchema,
  SubscriptionCancelPayload,
} from '../domain';

export const useSubscriptionPlans = () => {
  return useQuery({
    queryKey: ['billing', 'subscriptionPlans'],
    queryFn: () => {
      return axios
        .get('/v1/customers/billing/subscriptions/plans')
        .then((res) => subscriptionPlansSchema.parse(res.data.plans));
    },
    staleTime: Infinity,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useSubscriptionPlansData = () => assertQueryData(useSubscriptionPlans());

export const useBuySubscriptionPlan = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (data: SetSubscriptionPlanPayload) => {
      return axios
        .post('/v1/customers/billing/subscriptions', data)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onSuccess: ({ subscription }) => {
      client.invalidateQueries(qk.me);
      client.setQueryData<Billing>(['billing'], (prev) => prev && { ...prev, subscription });
    },
  });
};

export const useCancelSubcription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: SubscriptionCancelPayload }) => {
      return axios
        .post(`/v1/customers/billing/subscriptions/${id}/cancel`, data)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onSuccess: (billing) => {
      queryClient.invalidateQueries(qk.me);
      queryClient.setQueryData<Billing>(['billing'], billing);
    },
  });
};

const useSetTopup = () => {
  const queryClient = useQueryClient();

  return (topUp: boolean) => {
    queryClient.setQueryData<Billing>(
      ['billing'],
      (prev) =>
        prev && {
          ...prev,
          subscription: prev.subscription ? { ...prev.subscription, topUp } : null,
        },
    );
  };
};

export const useTopupSubscription = (id: number) => {
  const queryClient = useQueryClient();
  const setTopup = useSetTopup();
  const setUser = useSetUser();

  return useMutation({
    mutationFn: (data: TopupSubscriptionPayload) => {
      return axios
        .put(`/v1/customers/billing/subscriptions/${id}`, data)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onMutate: ({ topUp }) => {
      queryClient.cancelQueries(['billing']);
      setTopup(topUp);
      return topUp;
    },
    onError: (topUp) => setTopup(!topUp),
    onSuccess: (billing) => {
      queryClient.setQueryData<Billing>(['billing'], billing);
      setUser({ balance: billing.subscription!.creditBalance });
    },
  });
};

export const useRenewTopupSubscription = () => {
  const queryClient = useQueryClient();
  const setUser = useSetUser();

  return useMutation({
    mutationFn: ({ data, id }: { data: RenewTopupPayload; id: number }) => {
      return axios
        .post(`/v1/customers/billing/subscriptions/${id}/renew`, data)
        .then((res) => billingSchema.parse(res.data.data));
    },
    onSuccess: (billing) => {
      queryClient.setQueryData<Billing>(['billing'], billing);
      setUser({ balance: billing.subscription!.creditBalance });
    },
  });
};
