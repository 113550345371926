import {
  ORDER_FILE_UPLOAD_REQUEST,
  ORDER_FILE_UPLOAD_SUCCESS,
  ORDER_FILE_UPLOAD_FAILURE,
  ORDER_FILE_UPLOAD_REMOVE,
  RESTORE_ORDER_FILES,
  ORDER_FILE_PROGRESS,
  RESET_ORDER_FILES,
  RESET_FILE_UPLOAD_STATE,
  STYLE_FILE_UPLOAD_REQUEST,
  STYLE_FILE_UPLOAD_SUCCESS,
  STYLE_FILE_UPLOAD_FAILURE,
  STYLE_FILE_UPLOAD_REMOVE,
  RESTORE_STYLE_FILE,
  STYLE_FILE_PROGRESS,
  STYLE_PREFERENCES_FILE_UPLOAD_REQUEST,
  STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS,
  STYLE_PREFERENCES_FILE_UPLOAD_FAILURE,
  STYLE_PREFERENCES_FILE_UPLOAD_REMOVE,
  STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED,
  STYLE_PREFERENCES_FILE_UPLOAD_DROP,
  STORE_ABORT_CONTROLLER,
  REMOVE_ABORT_CONTROLLER,
  STOP_FILE_UPLOAD,
  TOGGLE_OWN_FILES_CHECKBOX,
  ORDER_REFERENCE_FILES,
} from './file-upload.types';

const initialState = {
  orderFiles: {},
  styleFile: {},
  referenceFiles: {},
  quoteFile: {},
  ownFiles: false,
  stylePreferencesFile: {},
};

const newFile = {
  name: '',
  savedName: '',
  isUploading: true,
  hasError: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_ORDER_FILES:
      return {
        ...state,
        orderFiles: initialState.orderFiles,
      };

    case RESET_FILE_UPLOAD_STATE:
      return initialState;

    case ORDER_FILE_UPLOAD_REQUEST: {
      const requestKey = action.payload.id;
      const requestObject = state.orderFiles;
      const newFileObj = {
        ...newFile,
        name: action.payload.file.name,
      };
      const requestNewObject = {
        ...requestObject,
        [requestKey]: {
          ...newFileObj,
        },
      };
      return {
        ...state,
        orderFiles: requestNewObject,
      };
    }

    case ORDER_FILE_PROGRESS: {
      return {
        ...state,
        orderFiles: {
          ...state.orderFiles,
          [action.payload.id]: {
            ...state.orderFiles[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };
    }

    case ORDER_FILE_UPLOAD_SUCCESS: {
      const successKey = action.payload.id;
      const successObject = state.orderFiles;

      if (!successObject[successKey]) {
        // if file already deleted
        return state;
      }

      const successFileUpdated = {
        ...successObject[successKey],
        isUploading: false,
        savedName: action.payload.file.name,
        uuid: action.payload.file.uuid,
        type: action.payload.file.type,
        progress: 100,
      };

      const successfulFiles = {};
      Object.entries(successObject).forEach(([key, value]) => {
        if (!value.hasError) {
          successfulFiles[key] = value;
        }
      });

      const successNewObject = {
        ...successfulFiles,
        [successKey]: {
          ...successFileUpdated,
        },
      };

      return {
        ...state,
        orderFiles: successNewObject,
      };
    }

    case ORDER_FILE_UPLOAD_FAILURE: {
      const key = action.error.id;
      const obj = state.orderFiles;
      if (!obj[key]) {
        // if file already deleted
        return state;
      }
      const fileUpdated = {
        ...obj[key],
        isUploading: false,
        hasError: true,
        errorMessage: action.error.message,
      };
      const newObj = {
        ...obj,
        [key]: fileUpdated,
      };
      return {
        ...state,
        orderFiles: newObj,
      };
    }

    case ORDER_FILE_UPLOAD_REMOVE: {
      const newObject = { ...state.orderFiles };
      delete newObject[action.payload];
      return {
        ...state,
        orderFiles: newObject,
      };
    }

    case ORDER_REFERENCE_FILES.REQUEST: {
      return {
        ...state,
        referenceFiles: {
          ...state.referenceFiles,
          [action.payload.id]: {
            ...newFile,
            name: action.payload.file.name,
          },
        },
      };
    }

    case ORDER_REFERENCE_FILES.SUCCESS: {
      if (!state.referenceFiles[action.payload.id]) {
        // if file already deleted
        return state;
      }

      const successfulFiles = {};
      Object.entries(state.referenceFiles).forEach(([key, value]) => {
        if (!value.hasError) {
          successfulFiles[key] = value;
        }
      });

      return {
        ...state,
        referenceFiles: {
          ...successfulFiles,
          [action.payload.id]: {
            ...state.referenceFiles[action.payload.id],
            isUploading: false,
            savedName: action.payload.file.name,
            uuid: action.payload.file.uuid,
            type: action.payload.file.type,
            progress: 100,
          },
        },
      };
    }

    case ORDER_REFERENCE_FILES.FAILURE: {
      if (!state.referenceFiles[action.error.id]) {
        // if file already deleted
        return state;
      }

      return {
        ...state,
        referenceFiles: {
          ...state.referenceFiles,
          [action.error.id]: {
            ...state.referenceFiles[action.error.id],
            isUploading: false,
            hasError: true,
            errorMessage: action.error.message,
          },
        },
      };
    }

    case ORDER_REFERENCE_FILES.PROGRESS: {
      return {
        ...state,
        referenceFiles: {
          ...state.referenceFiles,
          [action.payload.id]: {
            ...state.referenceFiles[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };
    }

    case ORDER_REFERENCE_FILES.REMOVE: {
      const newObject = { ...state.referenceFiles };
      delete newObject[action.payload];

      return {
        ...state,
        referenceFiles: newObject,
      };
    }

    case ORDER_REFERENCE_FILES.RESTORE: {
      const files = action.payload.reduce(
        (accumulator, file) => ({ ...accumulator, [file.name]: file }),
        [],
      );

      return {
        ...state,
        referenceFiles: files,
      };
    }

    case STYLE_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        styleFile: {
          ...newFile,
          name: action.payload.file.name,
        },
      };

    case STORE_ABORT_CONTROLLER:
      return {
        ...state,
        abortController: action.payload,
      };

    case REMOVE_ABORT_CONTROLLER:
      return {
        ...state,
        abortController: null,
      };

    case STOP_FILE_UPLOAD: {
      const { abortController } = state;
      if (abortController) {
        abortController.abort();
      }
      return {
        ...state,
        abortController: null,
      };
    }

    case STYLE_FILE_PROGRESS:
      return {
        ...state,
        styleFile: {
          ...state.styleFile,
          progress: action.payload.progress,
        },
      };

    case STYLE_FILE_UPLOAD_SUCCESS:
      if (state.styleFile.name) {
        return {
          ...state,
          styleFile: {
            ...state.styleFile,
            isUploading: false,
            savedName: action.payload.file.name,
            uuid: action.payload.file.uuid,
            type: action.payload.file.type,
            progress: 100,
          },
        };
      }
      return state;

    case STYLE_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        styleFile: {
          ...state.styleFile,
          isUploading: false,
          hasError: true,
          errorMessage: action.error.message,
        },
      };
    case STYLE_FILE_UPLOAD_REMOVE:
      return {
        ...state,
        styleFile: {},
      };

    case TOGGLE_OWN_FILES_CHECKBOX:
      return {
        ...state,
        ownFiles: !state.ownFiles,
      };

    case RESTORE_ORDER_FILES: {
      const files = action.payload.reduce(
        (accumulator, file) => ({ ...accumulator, [file.name]: file }),
        [],
      );

      return {
        ...state,
        orderFiles: files,
      };
    }

    case RESTORE_STYLE_FILE:
      return {
        ...state,
        styleFile: action.payload,
      };

    // STYLE PREFERENCES FILE UPLOAD
    case STYLE_PREFERENCES_FILE_UPLOAD_REQUEST: {
      const stylePreferencesRequestKey = action.payload.id;
      const stylePreferencesRequestObject = state.stylePreferencesFile;
      const stylePreferencesNewFileObj = {
        ...newFile,
        name: action.payload.file.name,
      };

      const stylePreferencesRequestNewObject = {
        ...stylePreferencesRequestObject,
        [stylePreferencesRequestKey]: {
          ...stylePreferencesNewFileObj,
        },
      };

      return {
        ...state,
        stylePreferencesFile: stylePreferencesRequestNewObject,
      };
    }

    case STYLE_PREFERENCES_FILE_UPLOAD_SUCCESS: {
      const stylePreferencesSuccessKey = action.payload.id;
      const stylePreferencesSuccessObject = state.stylePreferencesFile;

      if (
        !stylePreferencesSuccessObject[stylePreferencesSuccessKey] &&
        action.payload.key !== 'stylePreferencesInit'
      ) {
        return state;
      }

      const stylePreferencesSuccessFileUpdated = {
        ...stylePreferencesSuccessObject[stylePreferencesSuccessKey],
        isUploading: false,
        savedName: action.payload.file.name,
        uuid: action.payload.file.uuid,
        type: action.payload.file.type,
      };

      const stylePreferencesSuccessNewObject = {
        ...stylePreferencesSuccessObject,
        [stylePreferencesSuccessKey]: {
          ...stylePreferencesSuccessFileUpdated,
        },
      };
      return {
        ...state,
        stylePreferencesFile: stylePreferencesSuccessNewObject,
      };
    }

    case STYLE_PREFERENCES_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        stylePreferencesFile: {
          ...state.stylePreferencesFile,
          [action.error.name]: {
            ...state.stylePreferencesFile[action.error.name],
            isUploading: false,
            hasError: true,
            errorMessage: action.error.message,
          },
        },
      };

    case STYLE_PREFERENCES_FILE_UPLOAD_REMOVE: {
      const stylePreferencesFile = { ...state.stylePreferencesFile };
      delete stylePreferencesFile[action.payload.file];
      return {
        ...state,
        stylePreferencesFile,
      };
    }

    case STYLE_PREFERENCES_FILE_UPLOAD_DROP:
      return {
        ...state,
        stylePreferencesFile: {},
      };

    case STYLE_PREFERENCES_FILE_UPLOAD_REMOVE_FAILED: {
      const files = { ...state.stylePreferencesFile };
      files.forEach((file) => {
        if (file.hasError) {
          delete files[file.name];
        }
      });

      return {
        ...state,
        stylePreferencesFile: files,
      };
    }

    default:
      return state;
  }
};
